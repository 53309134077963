<nb-card>
  <nb-card-header>
    New file for version: {{gameVersion}}
  </nb-card-header>

  <nb-card-body>
    <input 
        #fileName 
        nbInput 
        placeholder="FileName"
      >

      <nb-select
        #fileSelector
        placeholder="-- existing files --"
        size ="tiny"
        (selectedChange)="onSelectPredefined($event)">
        <nb-option 
          *ngFor="let fileName of canCreateFiles"
          value="{{fileName}}">
            {{fileName}}
        </nb-option>
      </nb-select>

  </nb-card-body>

  <nb-card-footer>
    <button 
      nbButton 
      class="cancel" 
      status="danger" 
      (click)="cancel()"
      #cancelButton
      >
      Cancel
    </button>
    <button 
      nbButton 
      status="success" 
      (click)="submit(fileName.value)"
      #sendButton
      >
      Create
    </button>
  </nb-card-footer>
</nb-card>
