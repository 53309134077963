import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { FileExporData, FileExporDbData, VersionExportStruct } from 'core/CommonStructs';
import { FilesState, FileVersions, GameFilesData, RemoteConfigGameFiles } from 'core/ServiceStructs';
import { UserService } from 'services/user-service';
import * as JSZip from 'jszip';
import { FileMetadata, VersionMetadata } from 'contracts/FileMetadata';
import { BackendService } from 'services/backend-service';

@Component({
  selector:'ngx-dialog-files-export',
  templateUrl: 'dialog-files-export.component.html',
  styleUrls:  ['dialog-files-export.component.scss'],
})
export class DialogFilesExportComponent implements OnInit {
  constructor(
      protected ref: NbDialogRef<DialogFilesExportComponent>,
      private user: UserService,
      private backend: BackendService,
      private renderer: Renderer2
    ) {}

  gameVersion:string = "0.0.0";
  gameFiles:FileMetadata[] = [];
  activeFiles:any;

  downloading:boolean = false;

  @ViewChild('dwnld') fakeLoadButton; 
  @ViewChild('exportButton') exportButton; 
  @ViewChild('zipButton') zipButton; 
  

  preExportFilter:any = {};

  exportData:VersionExportStruct = new VersionExportStruct();

  ngOnInit() {
    this.exportData.Info.GameVersion = this.gameVersion;
    this.exportData.Info.Owner = this.user.getUserMail();

    //this.fireStorage.subscribeOnServiceInited(this.onStorageReady.bind(this));
    this.getAllFilesToExport();
  }

  getAllFilesToExport() {
    this.gameFiles.map(file => {
      const name = file.file;
      const versions = file.versions;

      let exportVersion = null;

      versions.map(version => {
        const toExport = this.isActiveFileVersion(name, version?.version);
        if (toExport)
          exportVersion = version?.version;
      });

      this.preExportFilter[name] = {
        export : exportVersion != null,
        version : exportVersion ?? "0",
        loaded : false
      };
    });
  }

  onStorageReady() {}

  isActiveFileVersion(fileName, version) {
    const fileInfo = this.activeFiles.find(x => x.name == fileName);
    const fileData = fileInfo.versions;

    if (fileData[this.gameVersion] == version){
      return true;
    }
    
    return false;
  }

  isFileToExport(fileName) {
    return this.preExportFilter[fileName].export;
  }
  isFileVersionToExport(fileName, version) {
    return this.preExportFilter[fileName].version == version;
  }


  isFileLoaded(fileName) {
    return this.preExportFilter[fileName].loaded;
  }

  onUpdateNote(event) {
    if (this.downloading) {
      return;
    }

    const data = event?.target?.value;
    this.exportData.Info.Notes = data;
  }

  onFileChecked(fileName, state) {
    if (this.downloading) {
      return;
    }

    this.preExportFilter[fileName].export = state;
  }
  onFileVersionChecked(fileName, version, state) {
    if (this.downloading) {
      return;
    }

    if (state) {
      this.preExportFilter[fileName].version = version;
    }
  }
  
  async onLoadStart(exportDump) {
    if (this.downloading) {
      return;
    }
    
    this.renderer.setAttribute(this.exportButton.hostElement.nativeElement, "disabled", "true");
    this.renderer.setAttribute(this.zipButton.hostElement.nativeElement, "disabled", "true");
    
    this.downloading = true;

    const listQueue:Promise<any>[] = [];


    for (const configName in this.preExportFilter) {
      if (!this.preExportFilter[configName].export) {
        continue;
      }

      const fileVersion = this.preExportFilter[configName].version;
      const fileState:FileMetadata = this.gameFiles.find(x => x.file == configName);
      const versionState:VersionMetadata = fileState.versions.find(x => x.version == fileVersion);

      const fileData:FileExporData = new FileExporData();

      fileData.Name = configName;
      fileData.Version = fileVersion;
      
      fileData.Db = new FileExporDbData();
      fileData.Db.Note = versionState.notes;
      fileData.Db.Owner = versionState.owner;


      const promise = this.backend.REST_Configs_GetFileData(this.gameVersion, configName, fileData.Version);
      promise.then((data) => {
        const json = JSON.parse(data.json);
        fileData.Data = json;

        this.preExportFilter[configName].loaded = true;
      })

      listQueue.push(promise);

      this.exportData.Files.push(fileData);
    }

    Promise.all(listQueue).then(() => {
      if (exportDump) {
        this.onExportDump();
      } else {
        this.onExportZip();
      }
    })

  }

  onExportDump() {
    this.exportData.Info.Time = Math.round(Date.now() / 1000);
    
    const strJson = JSON.stringify(this.exportData, null, 2);

    const stamp = Math.round(Date.now() / 1000);

    const filename = `Export_${this.user.getCurrentProjectId()}_v${this.gameVersion}_${stamp}.gdump`;
    const blob = new Blob([strJson], { type: "text/plain" });

    const link = this.fakeLoadButton.nativeElement;
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  }

  onExportZip() {
    const zip = new JSZip();

    for (const file of this.exportData.Files) {
      const fName = file.Name + ".json";
      const fData = JSON.stringify(file.Data, null, 2);

      zip.file(fName, fData);
    }

    const stamp = Math.round(Date.now() / 1000);

    const filename = `Configs_${this.user.getCurrentProjectId()}_v${this.gameVersion}_${stamp}.zip`;

    zip.generateAsync({ type: 'blob' }).then(data => {

      const link = this.fakeLoadButton.nativeElement;
      link.href = window.URL.createObjectURL(data);
      link.download = filename;
      link.click();
    });
  }
}
