import { NgModule } from "@angular/core";
import {  NbCardModule, 
          NbUserModule, 
          NbButtonModule, 
          NbIconModule, 
          NbRadioModule, 
          NbInputModule, 
          NbActionsModule, 
          NbDatepickerModule,
          NbCheckboxModule,
          NbSelectModule} from "@nebular/theme";

import { ThemeModule } from "app/@theme/theme.module";
import { DialogEnterStringComponent } from "./dialog-enter-string/dialog-enter-string.component";
import { DialogNewFileVersionComponent } from "./dialog-new-file-version/dialog-new-file-version.component";
import { DialogNewFileComponent } from "./dialog-new-file/dialog-new-file.component";
import { DialogViewFileComponent } from "./dialog-view-file/dialog-view-file.component";
import { NgJsonEditorModule } from "ang-jsoneditor";
import { DialogFilesExportComponent } from "./dialog-files-export/dialog-files-export.component";
import { DialogFilesImportComponent } from "./dialog-files-import/dialog-files-import.component";
import { DialogValidateUserChangesComponent } from "./dialog-validate-user-changes/dialog-validate-user-changes.component";
import { NgxDiffModule } from 'ngx-diff';

@NgModule({
  imports: [
    ThemeModule,
    NbCardModule,
    NbUserModule,
    NbButtonModule,
    NbIconModule,

    NbRadioModule,
    NbCheckboxModule,
    NbSelectModule,
    NbDatepickerModule,

    NbInputModule,
    NbActionsModule,
    NbSelectModule,

    NgJsonEditorModule,
    NgxDiffModule,
  ],
  declarations: [
    DialogEnterStringComponent,
    DialogNewFileComponent,
    DialogNewFileVersionComponent,
    DialogViewFileComponent,
    
    DialogFilesExportComponent,
    DialogFilesImportComponent,
    DialogValidateUserChangesComponent,
  ],
  exports : [
    DialogEnterStringComponent,
    DialogNewFileComponent,
    DialogNewFileVersionComponent,
    DialogViewFileComponent,
    
    DialogFilesExportComponent,
    DialogFilesImportComponent,
    DialogValidateUserChangesComponent,
  ]
})
export class DialogsModule { }
