export class FileMetadata {
    public file: string = "";
    public versions: VersionMetadata[] = [];
}

export class VersionMetadata {
    public version   : string        = "";
    public owner     : string        = "";
    public notes     : string        = "";
    public created   : number        = 0;
}
/*
[
    {
        "file": "Street1",
        "versions": [
            {
                "version": "1",
                "created": 1709566793,
                "notes": "Initial file [Street1] v:1.2.0",
                "owner": "return4serv@gmail.com"
            },
            {
                "version": "2",
                "created": 1709633269,
                "notes": "Update #2 for file [Street1] v:1.2.0",
                "owner": "return4serv@gmail.com"
            },
            {
                "version": "3",
                "created": 1709644762,
                "notes": "Update #3 for file [Street1] v:1.2.0",
                "owner": "return4serv@gmail.com"
            },
            {
                "version": "4",
                "created": 1709718068,
                "notes": "Update #4 for file [Street1] v:1.2.0",
                "owner": "return4serv@gmail.com"
            }
        ]
    }
]
*/