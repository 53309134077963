import { Injectable }       from    '@angular/core';
import { UserService }      from    './user-service';
import { ServiceBase } from './service-base';
import { ToastrService } from './toastr-service';
import { HttpClient } from '@angular/common/http';
import { IBackendRoute } from 'contracts/IBackendRoute';
import { Response_Message } from 'contracts/response/Response_Message';
import { BackendConfig } from 'contracts/backen-configd';
import { BackendRoutes } from 'contracts/backend-routes';
import { Response__ERROR } from 'contracts/response/DataModels/Response__ERROR';
import { Response_Project_Select } from 'contracts/response/DataModels/Response_Project_Select';
import { Response_Versions_GetAll } from 'contracts/response/DataModels/Response_Versions_GetAll';
import { RemoteConfigsGameVersion } from 'core/ServiceStructs';
import { Response_Versions_SetCurrent } from 'contracts/response/DataModels/Response_Versions_SetCurrent';
import { Response_Versions_SetVisibility } from 'contracts/response/DataModels/Response_Versions_SetVisibility';
import { Request_Versions_GetAll } from 'contracts/request/DataModels/Request_Versions_GetAll';
import { Request_Project_Select } from 'contracts/request/DataModels/Request_Project_Select';
import { Request_Versions_SetCurrent } from 'contracts/request/DataModels/Request_Versions_SetCurrent';
import { Request_Versions_SetVisibility } from 'contracts/request/DataModels/Request_Versions_SetVisibility';
import { Response_Configs_GetFilesForVersion } from 'contracts/response/DataModels/Response_Configs_GetFilesForVersion';
import { Request_Configs_GetFilesForVersion } from 'contracts/request/DataModels/Request_Configs_GetFilesForVersion';
import { Request_Configs_GetFileData } from 'contracts/request/DataModels/Request_Configs_GetFileData';
import { Response_Configs_GetFileData } from 'contracts/response/DataModels/Response_Configs_GetFileData';
import { Request_Configs_CreateFile } from 'contracts/request/DataModels/Request_Configs_CreateFile';
import { Response_Configs_CreateFile } from 'contracts/response/DataModels/Response_Configs_CreateFile';
import { Response_Configs_CreateFileVersion } from 'contracts/response/DataModels/Response_Configs_CreateFileVersion';
import { Request_Configs_CreateFileVersion } from 'contracts/request/DataModels/Request_Configs_CreateFileVersion';
import { Response_Configs_ApplyFileVersion } from 'contracts/response/DataModels/Response_Configs_ApplyFileVersion';
import { Request_Configs_ApplyFileVersion } from 'contracts/request/DataModels/Request_Configs_ApplyFileVersion';
import { Request_DashboardUsers_GetAllUsers } from '../contracts/request/DataModels/Request_DashboardUsers_GetAllUsers';
import { Response_DashboardUsers_GetAllUsers } from '../contracts/response/DataModels/Response_DashboardUsers_GetAllUsers';
import { Response_DashboardUsers_UpdateUser } from '../contracts/response/DataModels/Response_DashboardUsers_UpdateUser';
import { Request_DashboardUsers_UpdateUser } from '../contracts/request/DataModels/Request_DashboardUsers_UpdateUser';
import { Response_DashboardUsers_CreateUser } from '../contracts/response/DataModels/Response_DashboardUsers_CreateUser';
import { Request_DashboardUsers_CreateUser } from '../contracts/request/DataModels/Request_DashboardUsers_CreateUser';
import { Response_DashboardUsers_DeleteUser } from '../contracts/response/DataModels/Response_DashboardUsers_DeleteUser';
import { Request_DashboardUsers_DeleteUser } from '../contracts/request/DataModels/Request_DashboardUsers_DeleteUser';
import { Request_DashboardProjects_GetInfo } from '../contracts/request/DataModels/Request_DashboardProjects_GetInfo';
import { Response_DashboardProjects_GetInfo } from '../contracts/response/DataModels/Response_DashboardProjects_GetInfo';
import { Response_DashboardProjects_SetMeta } from '../contracts/response/DataModels/Response_DashboardProjects_SetMeta';
import { Request_DashboardProjects_SetMeta } from '../contracts/request/DataModels/Request_DashboardProjects_SetMeta';
import { ProjectInfoBackend, ProjectInfoMeta } from '../contracts/ProjectInfo';
import { Response_DashboardProjects_SetBackend } from '../contracts/response/DataModels/Response_DashboardProjects_SetBackend';
import { Request_DashboardProjects_SetBackend } from '../contracts/request/DataModels/Request_DashboardProjects_SetBackend';

@Injectable()
export class BackendService extends ServiceBase {
  
  constructor( 
    private user:UserService ,
    private http: HttpClient,
    private toastr: ToastrService
  ) { 
    super(); 
    this.user.subscribeOnServiceInited(this.onUserReady.bind(this));
  }

  private async onUserReady() {
    if (this.isServiceReady()) {
      return;
    }
    
    const userData = await this.REST_Auth();

    this.user.setupUserData(
      userData.data.email, 
      userData.data.token,
      userData.data.projectList
    );

    this.setServiceInited();
  }


  private getBackendAuthUrl() {
    return `${BackendConfig.SERVER_URI}/dash-auth`;
  }
  private getBackendApiUrl() {
    return `${BackendConfig.SERVER_URI}/dash-api`;
  }


  private async Post<Req, Resp>(uri:string, backendRoute:IBackendRoute, data?:Req):Promise<Response_Message<Resp>>{
    return this.PostInternal(uri, backendRoute, data);
  }
  private async PostInternal(uri:string, backendRoute:IBackendRoute, data?:any):Promise<Response_Message<any>> {
    const packet = {
      ...backendRoute,
      token : this.user.getSessionToken(),
      app : this.user.getCurrentProjectId(),
      data : data
    };

    return new Promise<Response_Message<any>>( (resolve, reject) => {
      console.log('POST', uri, packet);

      this.toastr.showBluePopup("Request", "Sending request to server");

      const post = this.http.post( uri, packet, { 
        headers : { 
          //...this.user.getUserData() 
        } 
      });

      post.toPromise().then( (response:Response_Message<any>) => {
        console.log('RESPONSE', response);

        if (response.result == BackendConfig.REQUEST_SUCCESS) {
          this.toastr.showGreenPopup("Request", "Request successfull");
          resolve(response);
        } else {
          const error = response.data as Response__ERROR;
          
          this.toastr.showRedPopup("API ERROR", error.message);

          reject(error.message);
        }
      })
    })
    
  }

  private async REST_Auth():Promise<Response_Message<any>> {
    const url = this.getBackendAuthUrl();

    const promise = new Promise<Response_Message<any>>(async (resolve, reject) => {
      const response = await this.Post<any, any>(
        url, BackendRoutes.AUTH, 
        {
          token : this.user.getAccessToken()
        }
      );

      if (response.result == BackendConfig.REQUEST_SUCCESS) {
        resolve(response);
      } else {
        reject(response);
      }
    });

    return promise;
  }

  public async REST_Project_SelectProject(projectId:string):Promise<Response_Project_Select> {
    const url = this.getBackendApiUrl();
    this.user.setCurrentProjectId(null);

    const promise = new Promise<Response_Project_Select>(async (resolve, reject) => {
      const response = await this.Post<Request_Project_Select, Response_Project_Select>(
        url, BackendRoutes.PROJECT_SELECT, 
        {
          project : projectId,
        }
      );

      this.user.setCurrentProjectId(response.data.project);

      if (response.result == BackendConfig.REQUEST_SUCCESS) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    });

    return promise;
  }


  public async REST_Versions_GetAllGameVesrions():Promise<Response_Versions_GetAll> {
    const url = this.getBackendApiUrl();

    const promise = new Promise<Response_Versions_GetAll>(async (resolve, reject) => {
      const response = await this.Post<Request_Versions_GetAll, Response_Versions_GetAll>(
        url, BackendRoutes.VERSIONS_GETALL, 
        { }
      );

      if (response.result == BackendConfig.REQUEST_SUCCESS) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    });

    return promise;
  }
  public async REST_Versions_SetCurrentVersions(gameVersion:RemoteConfigsGameVersion):Promise<Response_Versions_SetCurrent> {
    const url = this.getBackendApiUrl();

    const promise = new Promise<Response_Versions_SetCurrent>(async (resolve, reject) => {
      const response = await this.Post<Request_Versions_SetCurrent, Response_Versions_SetCurrent>(
        url, BackendRoutes.VERSIONS_SETCURRENT, 
        {
          active : gameVersion.active,
          minimal : gameVersion.minimal
        }
      );

      if (response.result == BackendConfig.REQUEST_SUCCESS) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    });

    return promise;
  }
  public async REST_Versions_UpdateVersion(gameVersion:string, enabled:boolean):Promise<Response_Versions_SetVisibility> {
    const url = this.getBackendApiUrl();

    const promise = new Promise<Response_Versions_SetVisibility>(async (resolve, reject) => {
      const response = await this.Post<Request_Versions_SetVisibility, Response_Versions_SetVisibility>(
        url, BackendRoutes.VERSIONS_UPDATEVERSION, 
        {
          version : gameVersion,
          enabled : enabled
        }
      );

      if (response.result == BackendConfig.REQUEST_SUCCESS) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    });

    return promise;
  }


  public async REST_Configs_GetConfigsForVersion(gameVersion:string):Promise<Response_Configs_GetFilesForVersion> {
    const url = this.getBackendApiUrl();

    const promise = new Promise<any>(async (resolve, reject) => {
      const response = await this.Post<Request_Configs_GetFilesForVersion, Response_Configs_GetFilesForVersion>(
        url, BackendRoutes.CONFIGS_GETCONFIGSFORVERSION, 
        {
          version : gameVersion
        }
      );

      if (response.result == BackendConfig.REQUEST_SUCCESS) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    });

    return promise;
  }
  public async REST_Configs_GetFileData(gameVersion:string, fileName:string, fileVersions):Promise<Response_Configs_GetFileData> {
    const url = this.getBackendApiUrl();

    const promise = new Promise<any>(async (resolve, reject) => {
      const response = await this.Post<Request_Configs_GetFileData, Response_Configs_GetFileData>(
        url, BackendRoutes.CONFIGS_GETFILEDATA, 
        {
          gameVersion : gameVersion,
          fileName : fileName,
          fileVersion : fileVersions
        }
      );

      if (response.result == BackendConfig.REQUEST_SUCCESS) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    });

    return promise;

  }
  public async REST_Configs_CreateFile(gameVersion:string, fileName:string):Promise<Response_Configs_CreateFile> {
    const url = this.getBackendApiUrl();

    const promise = new Promise<any>(async (resolve, reject) => {
      const response = await this.Post<Request_Configs_CreateFile, Response_Configs_CreateFile>(
        url, BackendRoutes.CONFIGS_CREATEFILE, 
        {
          gameVersion : gameVersion,
          fileName : fileName,
        }
      );

      if (response.result == BackendConfig.REQUEST_SUCCESS) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    });

    return promise;
  }
  public async REST_Configs_CreateFileVersion(gameVersion:string, fileName:string, fileVersionAux:string, notes:string, json:string):Promise<Response_Configs_CreateFileVersion> {
    const url = this.getBackendApiUrl();

    const promise = new Promise<any>(async (resolve, reject) => {
      const response = await this.Post<Request_Configs_CreateFileVersion, Response_Configs_CreateFileVersion>(
        url, BackendRoutes.CONFIGS_CREATEFILEVERSION, 
        {
          gameVersion : gameVersion,
          fileName : fileName,
          fileVersionAux : fileVersionAux,
          notes : notes,
          json : json
        }
      );

      if (response.result == BackendConfig.REQUEST_SUCCESS) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    });

    return promise;
  }
  public async REST_Configs_ApplyFileVersion(gameVersion:string, fileName:string, fileVersion:string):Promise<Response_Configs_ApplyFileVersion> {
    const url = this.getBackendApiUrl();

    const promise = new Promise<any>(async (resolve, reject) => {
      const response = await this.Post<Request_Configs_ApplyFileVersion, Response_Configs_ApplyFileVersion>(
        url, BackendRoutes.CONFIGS_APPLYFILEVERSION, 
        {
          gameVersion : gameVersion,
          fileName : fileName,
          fileVersion : fileVersion
        }
      );

      if (response.result == BackendConfig.REQUEST_SUCCESS) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    });

    return promise;
  }


  public async REST_DashboardUsers_GetAllUsers():Promise<Response_DashboardUsers_GetAllUsers> {
    const url = this.getBackendApiUrl();

    const promise = new Promise<any>(async (resolve, reject) => {
      const response = await this.Post<Request_DashboardUsers_GetAllUsers, Response_DashboardUsers_GetAllUsers>(
        url, BackendRoutes.DASHBOARDUSERS_GETALLUSERS, 
        { }
      );

      if (response.result == BackendConfig.REQUEST_SUCCESS) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    });

    return promise;
  }
  public async REST_DashboardUsers_UpdateUser(userID:string, permissions:any[]):Promise<Response_DashboardUsers_UpdateUser> {
    const url = this.getBackendApiUrl();

    const promise = new Promise<Response_DashboardUsers_UpdateUser>(async (resolve, reject) => {
      const response = await this.Post<Request_DashboardUsers_UpdateUser, Response_DashboardUsers_UpdateUser>(
        url, BackendRoutes.DASHBOARDUSERS_UPDATEUSER, 
        { 
          userMail : userID,
          permissions : permissions
        }
      );

      if (response.result == BackendConfig.REQUEST_SUCCESS) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    });

    return promise;
  }
  public async REST_DashboardUsers_CreateUser(userID:string):Promise<Response_DashboardUsers_CreateUser> {
    const url = this.getBackendApiUrl();

    const promise = new Promise<Response_DashboardUsers_CreateUser>(async (resolve, reject) => {
      const response = await this.Post<Request_DashboardUsers_CreateUser, Response_DashboardUsers_CreateUser>(
        url, BackendRoutes.DASHBOARDUSERS_CREATEUSER, 
        { 
          userMail : userID
        }
      );

      if (response.result == BackendConfig.REQUEST_SUCCESS) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    });

    return promise;
  }
  public async REST_DashboardUsers_DeleteUser(userID:string):Promise<Response_DashboardUsers_DeleteUser> {
    const url = this.getBackendApiUrl();

    const promise = new Promise<Response_DashboardUsers_DeleteUser>(async (resolve, reject) => {
      const response = await this.Post<Request_DashboardUsers_DeleteUser, Response_DashboardUsers_DeleteUser>(
        url, BackendRoutes.DASHBOARDUSERS_DELETEUSER, 
        { 
          userMail : userID
        }
      );

      if (response.result == BackendConfig.REQUEST_SUCCESS) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    });

    return promise;
  }


  public async REST_DashboardProjects_GetProjectInfo():Promise<Response_DashboardProjects_GetInfo> {
    const url = this.getBackendApiUrl();

    const promise = new Promise<any>(async (resolve, reject) => {
      const response = await this.Post<Request_DashboardProjects_GetInfo, Response_DashboardProjects_GetInfo>(
        url, BackendRoutes.DASHBOARDPROJECTS_GETPROJECTINFO, 
        { }
      );

      if (response.result == BackendConfig.REQUEST_SUCCESS) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    });

    return promise;
  }
  public async REST_DashboardProjects_SetProjectMeta(meta:ProjectInfoMeta):Promise<Response_DashboardProjects_SetMeta> {
    const url = this.getBackendApiUrl();

    const proj = this.user.getCurrentProjectId();
    const promise = new Promise<any>(async (resolve, reject) => {
      const response = await this.Post<Request_DashboardProjects_SetMeta, Response_DashboardProjects_SetMeta>(
        url, BackendRoutes.DASHBOARDPROJECTS_SETPROJECTMETA, 
        { 
          meta : meta
        }
      );

      if (response.result == BackendConfig.REQUEST_SUCCESS) {
        this.user.setProjectMeta(proj, meta);
        resolve(response.data);
      } else {
        reject(response.data);
      }
    });

    return promise;
  }
  public async REST_DashboardProjects_SetProjectBackend(backend:ProjectInfoBackend):Promise<Response_DashboardProjects_SetBackend> {
    const url = this.getBackendApiUrl();

    const promise = new Promise<any>(async (resolve, reject) => {
      const response = await this.Post<Request_DashboardProjects_SetBackend, Response_DashboardProjects_SetBackend>(
        url, BackendRoutes.DASHBOARDPROJECTS_SETPROJECTBACKEND, 
        { 
          backend : backend
        }
      );

      if (response.result == BackendConfig.REQUEST_SUCCESS) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    });

    return promise;
  }

}