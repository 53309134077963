import { Injectable }       from    '@angular/core';

@Injectable()
export class ServiceBase {
    private onServiceInitedCallbacks:Function[] = [];
    private isServiceInited:boolean = false;

    constructor() {
        this.isServiceInited = false;
    }

    public isServiceReady() { 
        return this.isServiceInited; 
    }

    public subscribeOnServiceInited(callback:Function) {
        if (this.isServiceInited) {
            callback();
        } else {
            this.onServiceInitedCallbacks.push(callback);
        }
    }
    
    protected setServiceInited() {
        for (const callback of this.onServiceInitedCallbacks)
            callback();
        
        this.onServiceInitedCallbacks = [];
        this.isServiceInited = true;
    }

}