import { Component, OnInit } from '@angular/core';
import { BackendService } from 'services/backend-service';
import { UserService } from 'services/user-service';


@Component({
  selector: 'ngx-login',
  styleUrls: ['login.component.scss'],
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  userLogged:boolean = false;

  userMail:string = "not logged";

  constructor(
     private user:UserService,
     private backend:BackendService,
    ) { }

    
  ngOnInit() {
    this.backend.subscribeOnServiceInited(this.onAppReady.bind(this));
  }

  onAppReady() {
    this.userLogged = true;

    this.userMail = this.user.getUserMail();
  }


  GetToken() {
    this.user.startAuth();
  }

}
