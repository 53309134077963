import { Injectable }       from    '@angular/core';
import { UserToken } from 'core/GoogleAPIStructs';
import { ServiceBase } from './service-base';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { ProjectInfoMeta, ProjectInfoSettings } from '../contracts/ProjectInfo';

declare var google: any;

@Injectable()
export class UserService extends ServiceBase {
  public OnProjectChanged:Subject<string> = new Subject();

  private client:any;
  private tokenInfo:UserToken;

  private userMail:string = "user";
  private sessionToken:string = "token";
  private projects:{[key: string]: ProjectInfoMeta} = {};

  private currentProject:string = null;

  constructor() { 
    super();

    this.client = google.accounts.oauth2.initTokenClient({
      client_id: environment.webClient,
      scope : 
        'https://www.googleapis.com/auth/userinfo.profile \
         https://www.googleapis.com/auth/userinfo.email'
      ,
      callback: (data) => {
        this.onTokenRecieved(data);
      },
    });

  }

  private onTokenRecieved(data) {
    this.tokenInfo = data;
    this.setServiceInited();
  }

  public startAuth() {
    this.client.requestAccessToken();
  }

  public getAccessToken():string {
    return this.tokenInfo.access_token;
  }

  public setupUserData(userMail:string, sessionToken:string, projects:any) {
    this.userMail = userMail;
    this.sessionToken = sessionToken;
    this.projects = projects;
  }
  public setCurrentProjectId(projectId:string) {
    this.currentProject = projectId;
    this.OnProjectChanged.next(this.currentProject);
  }
  
  public getUserMail():string {
    return this.userMail;
  }
  public getSessionToken():string {
    return this.sessionToken;
  }

  public getCurrentProjectId():string {
    return this.currentProject;
  }
  public hasSelectedProject():boolean {
    return this.currentProject != null;
  }

  public getAvailableApps():string[] {
    const projectIDs = Object.keys(this.projects);
    return projectIDs;
  }
  public getProjectMeta(projectID:string):ProjectInfoMeta {
    return this.projects[projectID] ?? new ProjectInfoMeta();
  }
  public setProjectMeta(projectID:string, meta:ProjectInfoMeta) {
    this.projects[projectID] = meta;
  }
  
}