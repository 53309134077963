import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { VersionExportStruct } from 'core/CommonStructs';
import { BackendService } from 'services/backend-service';

@Component({
  selector:'ngx-dialog-files-import',
  templateUrl: 'dialog-files-import.component.html',
  styleUrls:  ['dialog-files-import.component.scss'],
})
export class DialogFilesImportComponent implements OnInit {
  
  @ViewChild('fileInputName') fileInput; 
  @ViewChild('importButton') importButton; 
  
  constructor(
      protected ref: NbDialogRef<DialogFilesImportComponent>,
      private backend: BackendService,
      private renderer: Renderer2
    ) {}

  gameVersion:string = "0.0.0";
  uploading:boolean;

  importData:VersionExportStruct = new VersionExportStruct();
  preImportFilter:any = {};

  ngOnInit() {
  }

  onStorageReady() {}

  onFileSelected(data) {
    const files = data.target.files;
    if (files.length == 0) {
      return;
    }

    const targetFile = files[0];

    this.fileInput.nativeElement.value = targetFile.name;

    const reader = new FileReader();
    reader.onload = this.fileDataRecieced.bind(this);
    reader.readAsText(targetFile);
  }
  fileDataRecieced(event) {
    const fileText = event.target.result as string;

    try {
      const parsed = JSON.parse(fileText) as VersionExportStruct;
      this.preImportFilter = {};

      parsed.Files.map( (file) => {
        this.preImportFilter[file.Name] = {
          import : true,
          uploaded : false
        }
      })
      this.importData = parsed;
    } catch(e) {}
  }

  

  public getExportTime() {
    if (!this.importData.Info.Time || this.importData.Info.Time == 0)
        return "";

    const date = new Date(this.importData.Info.Time * 1000);
    return`${date.getFullYear()}/${date.getMonth()}/${date.getDate()} - ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`; 
  }


  isFileToImport(fileName) {
    return this.preImportFilter[fileName].import;
  }
  isFileLoaded(fileName) {
    return this.preImportFilter[fileName].uploaded;
  }
  onFileChecked(fileName, state) {
    this.preImportFilter[fileName].import = state;
  }

  async onImportStart() {
    if (this.uploading) {
      return;
    }

    this.renderer.setAttribute(this.importButton.hostElement.nativeElement, "disabled", "true");

    const gameVer:string = this.gameVersion;
    this.uploading = true;

    for (const fileData of this.importData.Files) {
      const fileName:string = fileData.Name;

      if (!this.preImportFilter[fileName].import) {
        continue;
      }

      let note = fileData.Db.Note;
      if (!note.startsWith("[Import]")) {
        note = "[Import][v" + fileData.Version + "] " + note;
      }

      await this.backend.REST_Configs_CreateFileVersion(
          gameVer,
          fileName,
          "",
          note,
          JSON.stringify(fileData.Data)
        ).
        then(x => {
          this.preImportFilter[fileName].uploaded = true;
        });
    }
    
    this.ref.close(true);
  }

}
