import { Component, Renderer2, ViewChild } from '@angular/core';
import { NbDialogRef, NbSelectComponent } from '@nebular/theme';
import { BackendService } from 'services/backend-service';

@Component({
  selector: 'ngx-dialog-new-file',
  templateUrl: 'dialog-new-file.component.html',
  styleUrls: ['dialog-new-file.component.scss'],
})
export class DialogNewFileComponent  {
  constructor(
    protected ref: NbDialogRef<DialogNewFileComponent>,
    private backend: BackendService,
    private renderer: Renderer2
    ) {}

  @ViewChild('fileName') fileInput; 
  @ViewChild('fileSelector') selector:NbSelectComponent; 

  
  @ViewChild('sendButton') sendButton; 
  @ViewChild('cancelButton') cancelButton; 
  
  gameVersion:string  = "0.0.0";
  canCreateFiles:string[]  = [];

  cancel() {
    this.ref.close();
  }

  submit(data) {
    if (!data || data == "") {
      this.ref.close(data);
      return;
    }

    this.renderer.setAttribute(this.sendButton.hostElement.nativeElement, "disabled", "true");
    this.renderer.setAttribute(this.cancelButton.hostElement.nativeElement, "disabled", "true");

    this.backend.REST_Configs_CreateFile(this.gameVersion, data).
      then(x => {
        this.ref.close(true);
      });
  }

  onSelectPredefined(data) {
    this.fileInput.nativeElement.value = data;
    this.selector.button.nativeElement.innerText = " -- existing files -- ";
  }
}
