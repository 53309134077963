<nb-card class="window-body">
  <nb-card-header>
    {{gameVersion}} // {{fileState.file}} 
  </nb-card-header>

  <nb-card-body>

    <nb-card>
      <nb-card-body>

        <div class="form-group">
          <label class="label">Version notes</label>
          <div style="display: flex;">
            <input type="text" 
                  value="{{fileNotes}}" 
                  nbInput fullWidth 
                  (change)="onUpdateNote($event)"
                  >
          </div>
        </div>

        <div class="form-group-set">
          <div class="grp grp-file-name">
            <label class="label">File name</label>
            <div style="display: flex;">
              <input type="text" 
                    class="readonly"
                    readonly value="{{fileNameInTotal}}" 
                    nbInput fullWidth 
                    >
            </div>
          </div>
          <div class="grp grp-file-version">
            <label class="label">Version</label>
            <div style="display: flex;">
              <input type="text" 
                    class="readonly"
                    readonly value="{{fileVersion}}" 
                    nbInput fullWidth 
                    >
            </div>
          </div>
          <div class="grp grp-file-aux">
            <label class="label">AUX</label>
            <div style="display: flex;">
              <input type="text" 
                    value="" (change)="onAuxUpdate($event)"
                    nbInput fullWidth 
                    >
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
    <nb-card>
      <nb-card-body>

        <div class="form-group-set">
          <div class="form-group">
            <label class="label">Import formatted sheet</label>
            <div style="display: flex;">
              <textarea 
                    type="text" 
                    class="sheet-input"
                    placeholder="Insert sheet data here" 
                    (change)="onSheetImport($event)"
                    nbInput fullWidth 
                    #sheetInput
                    ></textarea>
            </div>
          </div>

          <div class="form-group">
            <label class="label">Import file</label>
              <div class="files-upload-set">
                <div style="display: flex;">
                  <input 
                    #fileInputName
                    type="text" 
                    class="file-input-name readonly"
                    value="select file"
                    readonly nbInput fullWidth>
                        
                  <button 
                    nbButton 
                    class="file-input-button"
                    status="primary"
                    (click)="fileUpload.click()"
                    outline>
                    Select
                  </button>

                <input 
                  style="display: none;" 
                  type="file" 
                  accept=".json"
                  (change)="onFileSelected($event)"
                  #fileUpload>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label class="label">Import JSON</label>
          <json-editor 
                [options] ="editorOptions" 
                [data]    ="fileData" 
                (change)  ="onJsonChange($event)">
          </json-editor>
        </div>
        
        

        <div class="item-file-header-actions">
          <button nbButton 
            class="update-bttn"
            status="success" 
            (click)="onFileSendToStorage()"
            outline #uploadButton>
              Upload version
          </button>
        </div>

      </nb-card-body>
    </nb-card>
  </nb-card-body>

</nb-card>
