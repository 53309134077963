
export class VersionExportStruct {
    public Info:VersionExportInfo = new VersionExportInfo();
    public Files:FileExporData[] = [];
}
export class VersionExportInfo {
    public Notes:string;
    public Owner:string;
    public Time:number;
    public GameVersion:string;
}
export class FileExporData {
    public Name:string;
    public Version:string;

    public Db:FileExporDbData;
    public Data:any;
}
export class FileExporDbData {
    public Note:string;
    public Owner:string;
}