import { Component, ViewChild, Renderer2 } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { JsonEditorOptions } from 'ang-jsoneditor';
import { FileMetadata } from 'contracts/FileMetadata';
import { DialogViewFileComponent } from 'dialogs/dialog-view-file/dialog-view-file.component';
import { BackendService } from 'services/backend-service';
import { SheetParser } from 'utils-sheets-parser/SheetParser_Main'

@Component({
  selector: 'ngx-dialog-new-file-version',
  templateUrl: 'dialog-new-file-version.component.html',
  styleUrls: ['dialog-new-file-version.component.scss'],
})
export class DialogNewFileVersionComponent {
  constructor(
    protected ref: NbDialogRef<DialogViewFileComponent>, 
    private backend: BackendService,
    private renderer: Renderer2
  ) {}
  
  @ViewChild('fileInputName') fileInput; 
  @ViewChild('sheetInput') sheetImport; 
  @ViewChild('uploadButton') uploadButton; 

  gameVersion:string = "0.0.0";
  fileState:FileMetadata = new FileMetadata();
  fileVersion:string = "0";
  fileNotes:string = "";
  fileVersionAux:string = "";
  fileNameInTotal:string = "";

  fileData:any = {};
  exportFile:any = {};

  editorOptions: JsonEditorOptions = new JsonEditorOptions();

  ngOnInit() {
    this.editorOptions.modes            = ['code', 'tree'];
    this.editorOptions.mode             = 'code';
    this.editorOptions.enableSort       = false;
    this.editorOptions.statusBar        = false;
    this.editorOptions.navigationBar    = false;
    this.editorOptions.enableTransform  = false;
    this.editorOptions.search           = false;

    this.updateNotes();
    this.updateFullName();
  }

  onAuxUpdate(data) {
    const aux = data?.target?.value;
    this.fileVersionAux = aux ?? "";
    this.updateFullName();
  }

  updateNotes() {
    if (this.fileVersion == "1")
      this.fileNotes = "Initial file [" + this.fileState.file + "] v:" + this.gameVersion;
    else
      this.fileNotes = "Update #" + this.fileVersion + " for file [" + this.fileState.file + "] v:" + this.gameVersion;
  }

  updateFullName() {
    if (!this.fileVersionAux  || this.fileVersionAux  == "") {
      this.fileNameInTotal = this.fileState.file + "." + this.fileVersion + ".json";      
    } else {
      this.fileNameInTotal = this.fileState.file + "." + this.fileVersion + "." + this.fileVersionAux  + ".json";
    }
  }

  onUpdateNote(event) {
    const data = event?.target?.value;
    this.fileNotes = data;
  }

  onJsonChange(data) { 
    const isEvent = data instanceof Event;
    if (isEvent) { return };

    this.exportFile = data;
  }

  onFileSelected(data) {
    const files = data.target.files;
    if (files.length == 0) {
      return;
    }

    const targetFile = files[0];

    this.fileInput.nativeElement.value = targetFile.name;

    const reader = new FileReader();
    reader.onload = this.fileDataRecieced.bind(this);
    reader.readAsText(targetFile);
  }
  fileDataRecieced(event) {
    const fileText = event.target.result as string;

    try {
      const obj = JSON.parse(fileText);
      this.fileData = obj;
      this.exportFile = obj;
    } catch(e) {
      this.fileData = fileText;
      this.exportFile = fileText;
    }
  }

  onSheetImport(event) {
    const data = event?.target?.value;
    if (!data) {
      return;
    }

    const obj = SheetParser.parseSheet(data);

    this.sheetImport.nativeElement.value = "";
    this.fileData = obj;
    this.exportFile = obj;
  }

  onFileSendToStorage() {
    const json = JSON.stringify(this.exportFile);

    if (!json) {
      return;
    } 

    this.renderer.setAttribute(this.uploadButton.hostElement.nativeElement, "disabled", "true");

    this.backend.REST_Configs_CreateFileVersion(
        this.gameVersion, 
        this.fileState.file, 
        this.fileVersionAux, 
        this.fileNotes, 
        json).
      then(x => {
        this.ref.close(true);
      }).
      catch(() => {
        this.renderer.removeAttribute(this.uploadButton.hostElement.nativeElement, "disabled");
      });
  }
  
}
