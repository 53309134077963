import { Injectable } from '@angular/core';
import { NbGlobalPhysicalPosition, NbToastrService }  from '@nebular/theme';
   

@Injectable()
export class ToastrService {
  private _defaultCfg = {
    destroyByClick    : true,
    duration          : 3 * 1000,
    hasIcon           : false,
    preventDuplicates : false,
    position          : NbGlobalPhysicalPosition.BOTTOM_RIGHT,
    limit             : 5
  }

  constructor(private popups: NbToastrService) { }

  public showBluePopup(title:string, message:string) {
    this.popups.show(
      message,
      title,
      {
        ...this._defaultCfg,
        status: 'info',
      });
  }

  public showGreenPopup(title:string, message:string) {
    this.popups.show(
      message,
      title,
      {
        ...this._defaultCfg,
        status: 'success',
      });
  }
  public showRedPopup(title:string, message:string) {
    this.popups.show(
      message,
      title,
      {
        ...this._defaultCfg,
        status: 'danger',
      });
  }
  
}