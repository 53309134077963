import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector:'ngx-dialog-validate-user-changes',
  templateUrl: 'dialog-validate-user-changes.component.html',
  styleUrls:  ['dialog-validate-user-changes.component.scss'],
})
export class DialogValidateUserChangesComponent implements OnInit {
  isSame:boolean;

  constructor(
      protected ref: NbDialogRef<DialogValidateUserChangesComponent>
    ) {}

  oldUserData:any;
  newUserData:any;

  oldDocumentContents:string;
  newDocumentContents:string;

  ngOnInit() {
    this.oldDocumentContents = JSON.stringify(this.oldUserData, null, 2);
    this.newDocumentContents = JSON.stringify(this.newUserData, null, 2);

    this.isSame = this.oldDocumentContents == this.newDocumentContents;
  }

  onApprove() {
    this.ref.close(true);
  }

}
