import { SheetParser_Utils } from "./SheetParser_Utils";

export namespace SheetParser_Refs {
    export function buildRefs(headers):any {
        let referenceObject = {};
        
        for (const key of headers) {
            const isObject = key.includes("/");

            if (isObject) {
                referenceObject = ref_AddObject(referenceObject, key, "", "", null);
            }else {
                referenceObject = ref_AddField(referenceObject, key, "", "", null);
            }
        }

        return referenceObject;
    }

    function ref_AddField(referenceObject, fieldKey, preKeyPath, preKeyPathOriginal, container) {
        const isArray = SheetParser_Utils.util_CheckIsArray(fieldKey);
        const isSimpleArray = SheetParser_Utils.util_CheckIsSimpleArray(fieldKey);

        if (preKeyPath.length > 0) {
            preKeyPath = preKeyPath + "/";
        }

        if (preKeyPathOriginal.length > 0) {
            preKeyPathOriginal = preKeyPathOriginal + "/";
        }

        let fieldName = fieldKey;

        if (isArray) {
            fieldName = SheetParser_Utils.util_CleanArrayName(fieldKey);
        }

        if (isSimpleArray) {
            fieldName = SheetParser_Utils.util_CleanSimpleArrayName(fieldKey);
        }

        if (!referenceObject[fieldName]) {
            referenceObject[fieldName] = SheetParser_Utils.util_MakeStructDataObject(
                preKeyPath + fieldName,
                preKeyPathOriginal + 
                        (isSimpleArray ? fieldName : fieldKey),
                fieldName,
                isArray,
                isSimpleArray,
                false,
                container
            );

            if (isSimpleArray) {
                const existing = referenceObject[fieldName];
                const info = existing.__getInfo();

                const isOverride = SheetParser_Utils.util_CheckSimpleArrayOverrideName(fieldKey);
                const isData = SheetParser_Utils.util_CheckSimpleArraySignleData(fieldKey);

                if (isOverride) {
                    info.hasOverrideName = true;
                    info.simpleElements = [];
                } else if (isData) {
                    info.isOverrideValue = true;
                } else {
                    const startIdx = SheetParser_Utils.util_GetSimpleArrayIndex(fieldKey);
                    info.simpleElements = [startIdx];
                }
            }
        } else {
            const existing = referenceObject[fieldName];
            const info = existing.__getInfo();
            
            if (info.isSimpleArray) {

                const isOverride = SheetParser_Utils.util_CheckSimpleArrayOverrideName(fieldKey);
                const isData = SheetParser_Utils.util_CheckSimpleArraySignleData(fieldKey);

                if (isOverride) {
                    info.hasOverrideName = true;
                } else if (isData) {
                    info.isOverrideValue = true;
                } else {
                    const startIdx = SheetParser_Utils.util_GetSimpleArrayIndex(fieldKey);
                    info.simpleElements.push(startIdx);
                }
            }
        }

        return referenceObject;
    }
    function ref_AddObject(referenceObject, fieldKey, preKeyPath, preKeyPathOriginal, container) {
        const names = fieldKey.split("/");
        const current = names[0];
        const left = fieldKey.replace(current + "/", "");

        if (preKeyPath.length > 0) {
            preKeyPath = preKeyPath + "/";
        }

        if (preKeyPathOriginal.length > 0) {
            preKeyPathOriginal = preKeyPathOriginal + "/";
        }

        // - - - - - - - -

        const isArray = SheetParser_Utils.util_CheckIsArray(current);
        const isSimpleArray = SheetParser_Utils.util_CheckIsSimpleArray(current);

        let fieldName = current;

        if (isArray) {
            fieldName = SheetParser_Utils.util_CleanArrayName(fieldName);
        }

        if (isSimpleArray) {
            fieldName = SheetParser_Utils.util_CleanSimpleArrayName(fieldName);
        }

        // - - - fill current object
        if (!referenceObject[fieldName]) {
            referenceObject[fieldName] = SheetParser_Utils.util_MakeStructDataObject(
                preKeyPath + fieldName,
                preKeyPathOriginal + current,
                fieldName,
                isArray,
                isSimpleArray,
                true,
                container
            );
            
            if (isSimpleArray) {
                const existing = referenceObject[fieldName];
                const info = existing.__getInfo();

                const isOverride = SheetParser_Utils.util_CheckSimpleArrayOverrideName(fieldKey);
                const isData = SheetParser_Utils.util_CheckSimpleArraySignleData(fieldKey);

                if (isOverride) {
                    info.hasOverrideName = true;
                    info.simpleElements = [];
                } else if (isData) {
                    info.isOverrideValue = true;
                } else {
                    const startIdx = SheetParser_Utils.util_GetSimpleArrayIndex(fieldKey);
                    info.simpleElements = [startIdx];
                }
            }
        } else {
            const existing = referenceObject[fieldName];
            const info = existing.__getInfo();
            
            if (info.isSimpleArray) {

                const isOverride = SheetParser_Utils.util_CheckSimpleArrayOverrideName(fieldKey);
                const isData = SheetParser_Utils.util_CheckSimpleArraySignleData(fieldKey);

                if (isOverride) {
                    info.hasOverrideName = true;
                } else if (isData) {
                    info.isOverrideValue = true;
                } else {
                    const startIdx = SheetParser_Utils.util_GetSimpleArrayIndex(fieldKey);
                    info.simpleElements.push(startIdx);
                }
            }
        }
        // - - - 

        // - - - fill inner objects
        const isNextObject = left.includes("/");
        const currentElement = referenceObject[fieldName];
        const currentInfo = currentElement.__getInfo();

        if (isNextObject) {
            referenceObject[fieldName] = ref_AddObject(referenceObject[fieldName], left, preKeyPath + fieldName, preKeyPathOriginal + current, currentInfo);
        } else {
            referenceObject[fieldName] = ref_AddField(referenceObject[fieldName], left, preKeyPath + fieldName, preKeyPathOriginal + current, currentInfo);
        }
        // - - -

        return referenceObject;
    }
}