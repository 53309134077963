export namespace SheetParser_Utils {
    export function util_CheckEmpty(cell) {
        const isEmpty = cell === "" || cell === " ";
        const isUndefuned = cell == null || cell == undefined || cell == NaN;

        return isEmpty || isUndefuned;
    }

    export function util_CheckEmptyObject(obj) {
        
    }

    export function util_TrySerialize(str) {
        if (str.length == 0) {
            str = null;
        } else if (str.length == 1 && str === " ") {
            str = null;
        } else if (!isNaN(str)) {
            const float = parseFloat(str);
            if (!isNaN(float)) {
                str = float;
            } else {
                const int = parseInt(str);
                str = int;
            }
        } else if (str.length >= 4 || str.length <= 6)  {
            const low = str.toLowerCase();
            if (low == "true" || low == "истина")
                str = true;
            else if (low == "false" || low == "ложь")
                str = false;
        }  

        return str;
    }

    export function util_TrimArray(array) {
        const arraySize = array.length;
        
        let lastFilled = arraySize;
        const filtered = [];
        
        for (let i = arraySize - 1; i >= 0; i--) {
            if (util_CheckEmpty(array[i])){
                lastFilled = i;
            } else {
                break;
            }
        }

        for (let i = 0; i < lastFilled; i++) {
            filtered[i] = array[i];
        }
        

        return filtered;
    }

    export function util_CheckIsArray(fieldName) {
        return fieldName.includes("[]");
    }

    export function util_CheckIsSimpleArray(fieldName) {
        const arrayTest = /\[\d+\]/;
        const arrayNameTest = /\[name\]/;
        const arrayDaraTest = /\[data\]/;

        const indexTest = arrayTest.test(fieldName);
        const overrideTest = arrayNameTest.test(fieldName);
        const dataTest = arrayDaraTest.test(fieldName);
        
        return indexTest || overrideTest || dataTest;
    }
    export function util_GetSimpleArrayIndex(fieldName) {
        const arrayTest = /\[\d+\]/;
        const match = arrayTest.exec(fieldName) + "";
        const val = match.replace(/\[(\d+)\]/, "$1");

        return parseInt(val);
    }
    export function util_CheckSimpleArrayOverrideName(fieldName) {
        const arrayTest = /\[name\]/;
        const match = arrayTest.test(fieldName);

        return match;
    }
    export function util_CheckSimpleArraySignleData(fieldName) {
        const arrayTest = /\[data\]/;
        const match = arrayTest.test(fieldName);

        return match;
    }

    export function util_CleanArrayName(fieldName) {
        return fieldName.replace("[]", "");
    }
    export function util_CleanSimpleArrayName(fieldName) {
        return fieldName.replace(/\[(\d+)?(name)?(data)?\]/, "");
    }

    export function util_MakeStructDataObject(fullPath, originalPath, fieldName, isArray, isSimpleArray, isObject, container) {
        const infoObj = {
            fullPath: fullPath,
            originalPath: originalPath,
            fieldName: fieldName,
            hasOverrideName: false,
            isOverrideValue: false,
            overrideElements: [],
            isArray : isArray,
            isSimpleArray : isSimpleArray,
            isObject : isObject,
            container : container,
        };

        const self = Object.create({
            __getInfo : () => {
                return infoObj;
            }
        });

        return self;
    }

}