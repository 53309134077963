import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BackendService } from 'services/backend-service';
import { UserService } from 'services/user-service';


@Component({
  selector: 'ngx-project',
  styleUrls: ['project.component.scss'],
  templateUrl: './project.component.html',
})
export class ProjectComponent implements OnInit, OnDestroy {
  projectName:string = " ... ";
  
  private onProjectSubscription:Subscription;

  constructor(
     private user:UserService,
     private backend:BackendService,
    ) { }

    
  ngOnInit(): void {
    this.backend.subscribeOnServiceInited(this.onAppReady.bind(this));
    this.onProjectSubscription = this.user.OnProjectChanged.subscribe(this.onProjectUpdated.bind(this));
  }

  ngOnDestroy(): void {
    this.onProjectSubscription?.unsubscribe();
    this.onProjectSubscription = null;
  }

  onProjectUpdated(newProjectID:string) {
    this.projectName = newProjectID;
  }
  
  isProd(projectID) {
    const meta = this.user.getProjectMeta(projectID);
    return meta?.isProd ?? false; 
  }

  onAppReady() {
    // need ?
  }
}
