<nb-card class="window-body">
  <nb-card-header>
    {{gameVersion}} // {{fileState.file}} [{{fileVersion.version}}]
  </nb-card-header>

  <nb-card-body>
    <nb-card>
      <nb-card-body>
        
        <div class="form-group">
          <label class="label">Notes</label>
          <div style="display: flex;">
            <input type="text" 
                  readonly value="{{fileVersion.notes}}" 
                  nbInput fullWidth 
                >
          </div>
        </div>

        <div class="form-group-set">

          <div class="form-group">
            <label class="label">Owner</label>
            <div style="display: flex;">
              <input type="text" 
                    readonly value="{{fileVersion.owner}}" 
                    nbInput fullWidth 
                    >
            </div>
          </div>
          
          <div class="form-group">
            <label class="label">Created At</label>
            <div style="display: flex;">
              <input type="text" 
                    readonly value="{{fileVersion.created}}" 
                    nbInput fullWidth 
                    >
            </div>
          </div>
        </div>
        
        

      </nb-card-body>
    </nb-card>

    <nb-card>
      
      <nb-card-body>
        <div class="loader-holder">
          <span
            *ngIf="!fileLoaded" 
            class="loader-file">
            </span>
        </div>

        <json-editor 
                *ngIf="fileLoaded"
                [options] ="editorOptions" 
                [data]    ="fileData" >
        </json-editor>
      </nb-card-body>
    </nb-card>
  </nb-card-body>

</nb-card>
