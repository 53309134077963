<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>  
    <ngx-project></ngx-project>
  </div>
</div>

<div>
  <ngx-login></ngx-login>
</div>

<div class="theme-holder">
  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value">
      {{ theme.name }}
    </nb-option>
  </nb-select>
</div>
