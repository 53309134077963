<nb-card class="window-body">

  <nb-card-header>
    Validate changes
  </nb-card-header>

  <nb-card-body>
    <nb-card>
      
      <nb-card-body>
        <inline-diff 
          [oldText]="oldDocumentContents" 
          [newText]="newDocumentContents" 
          [lineContextSize]="4" 
          >
        </inline-diff>
      </nb-card-body>

    </nb-card>
  </nb-card-body>

  <nb-card-body 
    *ngIf="!isSame"
    style="overflow: unset">
    <nb-card>
      <button 
          nbButton 
          class="file-input-button"
          status="success"
          (click)="onApprove()"
          outline>
          Approve update
        </button>
    </nb-card>
  </nb-card-body>

</nb-card>
