import { SheetParser_Refs } from "./SheetParser_Refs";
import { SheetParser_Utils } from "./SheetParser_Utils";

export namespace SheetParser {
    export function parseSheet(input):any {
        const tableParsed = parseStringTable(input);
    
        const table = tableParsed.table;
        const headers = tableParsed.headers;
    
        const referenceStruct = SheetParser_Refs.buildRefs(headers);
        const splitedObjects = parse_ParseTableData(table, referenceStruct);
        const trimmed = trim_TrimEmptyObjects(splitedObjects);
    
        return trimmed;
    }

    function parseStringTable(input) {
        const filtered = input.replace(/\r/g, "");
    
        const rows = filtered.split('\n');
        const tableParsed = [];
    
        const headers = rows[0].split("\t");
    
        for (let r = 1; r < rows.length; r++) {
            const row = rows[r];
            const rowParsed = {};
            
            const columns = row.split("\t");
            for (let c = 0; c < columns.length; c++) {
                let col = columns[c];
                rowParsed[headers[c]] = col;
            }
    
            tableParsed.push(rowParsed);
        }
    
        return {
            headers: headers,
            table : tableParsed
        };
    }
    
    function trim_TrimEmptyObjects(mainArray) {
    
        for (const arrayObject of mainArray) {
            trim_TrimObject(arrayObject);
        }
    
        return mainArray;
    }
    function trim_TrimObject(obj) {
        let allItemsEmpty = true;
    
        const isArray = Array.isArray(obj);
        const isObject = typeof obj == "object";
    
        if (!isArray && isObject)
        {
            for (const key in obj) {
                let isEmpty = SheetParser_Utils.util_CheckEmpty(obj[key]);
                const objType = typeof obj[key];
    
                if (!isEmpty && objType == "object") {
                    const areInnerEmpty = trim_TrimObject(obj[key]);
                    if (areInnerEmpty)
                        isEmpty = true;
                } 
                
                if (allItemsEmpty && !isEmpty){
                    allItemsEmpty = false;
                }
    
                if (isEmpty)
                    delete(obj[key]);
            }
        } else if (!isArray && !isObject) {
            allItemsEmpty = SheetParser_Utils.util_CheckEmpty(obj);
        } else {
            let arrayIsEmpty = true;
    
            for (let arrayItem of obj) {
                const isEmpty = trim_TrimObject(arrayItem);
    
                if (arrayIsEmpty && !isEmpty){
                    arrayIsEmpty = false;
                }
            }
    
            allItemsEmpty = arrayIsEmpty;
        }
    
        return allItemsEmpty;
    }
    
    function parse_ParseTableData(table, referenceStruct:any[]) {
        const separatedTables = [];
    
        let fieldsForCurrentObject = [];
        let currentObject = {};
    
        const index = Object.values(referenceStruct)[0].__getInfo();
        const indexPath = index.originalPath;
    
        for (const row of table) {
            const rowCell = row[indexPath]; 
            const isEmpty = SheetParser_Utils.util_CheckEmpty(rowCell);
            const isStart = fieldsForCurrentObject.length == 0;
    
            if (!isEmpty && !isStart) {
                currentObject = parse_ParseTableGroup(fieldsForCurrentObject, referenceStruct);
                separatedTables.push(currentObject);
                fieldsForCurrentObject = [];
            }
    
            fieldsForCurrentObject.push(row);
        }
        
        currentObject = parse_ParseTableGroup(fieldsForCurrentObject, referenceStruct);
        separatedTables.push(currentObject);
    
        return separatedTables;
    }
    function parse_ParseTableGroup(tablesGroup, referenceStruct) {
        const returnObject = {};
    
        for (const key in referenceStruct) {
            const field = referenceStruct[key];
            if (typeof field == "function") {
                continue;
            }
    
            const info = field.__getInfo();
            
            // - - - - 
            if (!info.isObject && !info.isArray && !info.isSimpleArray) {
                const firstRow = tablesGroup[0];
                const serialized = SheetParser_Utils.util_TrySerialize(firstRow[info.originalPath]);
                returnObject[key] = serialized;
                continue;
            }
            // - - - - 
    
            // - - - - 
            if (info.isArray && !info.isObject) {
                returnObject[key] = [];
    
                for (const row of tablesGroup) {
                    const rowCell = row[info.originalPath]; 
                    const isEmpty = SheetParser_Utils.util_CheckEmpty(rowCell);
        
                    if (!isEmpty) {
                        const serialized = SheetParser_Utils.util_TrySerialize(rowCell);
                        returnObject[key].push(serialized);
                    }
                }
    
                returnObject[key] = SheetParser_Utils.util_TrimArray(returnObject[key]);
    
                continue;
            }
            // - - - - 
    
            // - - - - 
            if (info.isSimpleArray && !info.isObject) {
                let simpleKey = key;
                let filteredTables = [ tablesGroup[0] ];
                
                const maxIndex = Math.max(...info.simpleElements);
                
                if (info.hasOverrideName) {
                    filteredTables = [ ...tablesGroup ];
                }
    
                for (const row of filteredTables) {
                    
                    if (info.hasOverrideName) {
                        const nextName = row[info.originalPath + "[name]"]; 
                        if (nextName?.length > 0) {
                            simpleKey = nextName;
                            if (!info.overrideElements.includes(nextName))
                                info.overrideElements.push(nextName);
                        } else {
                            continue;
                        }
                    }
                    
                    if (info.isOverrideValue) {
                        const rowCell = row[info.originalPath + "[data]"]; 
                        const serialized = SheetParser_Utils.util_TrySerialize(rowCell);
                        returnObject[simpleKey] = serialized;
                    } else {
                        returnObject[simpleKey] = [];
                
                        for (let i = 0; i <= maxIndex; i++) {
                            returnObject[simpleKey].push(null);
                        }
            
                        for (const index of info.simpleElements) {
                            const rowCell = row[info.originalPath + "[" + index + "]"]; 
                            const isEmpty = SheetParser_Utils.util_CheckEmpty(rowCell);
            
                            if (!isEmpty) {
                                const serialized = SheetParser_Utils.util_TrySerialize(rowCell);
                                returnObject[simpleKey][index] = serialized;
                            }
                        }
            
                        returnObject[simpleKey] = SheetParser_Utils.util_TrimArray(returnObject[simpleKey]);
                    }
                    
                }
                
                
    
                continue;
            }
            // - - - - 
    
            if (info.isObject && !info.isArray && !info.isSimpleArray) {
                const obj = parse_ParseTableGroup(tablesGroup, field);
                returnObject[key] = obj;
                continue;
            }
    
            
            if (info.isObject && info.isArray && !info.isSimpleArray) {
                const obj = parse_ParseTableData(tablesGroup, field);
                returnObject[key] = obj;
                continue;
            }
    
        }
    
        return returnObject;
    }
}



