import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { JsonEditorOptions } from 'ang-jsoneditor';
import { FileMetadata, VersionMetadata } from 'contracts/FileMetadata';
import { BackendService } from 'services/backend-service';

@Component({
  selector: 'ngx-dialog-view-file',
  templateUrl: 'dialog-view-file.component.html',
  styleUrls: ['dialog-view-file.component.scss'],
})
export class DialogViewFileComponent implements OnInit {
  constructor(
      protected backend: BackendService,
      protected ref: NbDialogRef<DialogViewFileComponent>,
    ) {}

  gameVersion:string = "0.0.0";
  fileState:FileMetadata = new FileMetadata();
  fileMetadata:FileMetadata = new FileMetadata();
  fileVersion:VersionMetadata = new VersionMetadata();

  filePath:string = "//";

  fileData:any = {};
  fileLoaded:boolean;

  editorOptions: JsonEditorOptions = new JsonEditorOptions();

  ngOnInit() {
    this.editorOptions.modes            = ['code', 'tree'];
    this.editorOptions.mode             = 'code';
    this.editorOptions.enableSort       = false;
    this.editorOptions.statusBar        = false;
    this.editorOptions.navigationBar    = false;
    this.editorOptions.enableTransform  = false;
    this.editorOptions.search           = false;
    
    this.backend.REST_Configs_GetFileData(this.gameVersion, this.fileState.file, this.fileVersion.version).
      then( data => {
      this.fileData = JSON.parse(data.json);
      this.fileLoaded = true;
    });
  }
}
