<div class="login-holder">

    <button *ngIf="!userLogged"
      class="google-btn"
      (click) = "GetToken()"
      >
      <img 
        src="https://developers.google.com/static/identity/images/g-logo.png" 
        class="google-btn-icon">
      Log in with Google
    </button>

    <div *ngIf="userLogged"
      class="user-info"
      >

      <div class="user-data">
        <div class="user-data-name">
          Logged as:
        </div>
        <div class="user-data-email">
          {{userMail}}
        </div>
      </div>
    </div>

</div>
