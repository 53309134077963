<nb-card class="window-body">
  <nb-card-header>
    Import {{gameVersion}} 
  </nb-card-header>

  <nb-card-body >

    <nb-card>
      
      <nb-card-body class="files-info-body">

        <div class="form-group import-set">
          <label class="label">Import file</label>
            <div class="files-upload-set">
              <div style="display: flex;">
                <input 
                  #fileInputName
                  type="text" 
                  class="file-input-name readonly"
                  placeholder="Select file"
                  readonly nbInput fullWidth>
                      
                <button 
                  nbButton 
                  class="file-input-button"
                  status="primary"
                  (click)="fileUpload.click()"
                  outline>
                  Select
                </button>

                <button nbButton 
                  class="update-bttn import-button"
                  status="success" 
                  outline
                  (click)="onImportStart()"
                  #importButton>
                    Import files
                </button>

              <input 
                style="display: none;" 
                type="file" 
                accept=".gdump"
                (change)="onFileSelected($event)"
                #fileUpload>
            </div>
          </div>
        </div>

        <div class="form-group-set import-info-set">
          <div class="grp grp-file-name">
            <label class="label">Import notes</label>
            <div style="display: flex;">
              <input type="text" 
                    class="readonly"
                    readonly value="{{importData.Info.Notes}}" 
                    nbInput fullWidth 
                    >
            </div>
          </div>
        </div>

        <div class="form-group-set import-info-set padding-bottom" >
          
          <div class="grp grp-file-version">
            <label class="label">Game version</label>
            <div style="display: flex;">
              <input type="text" 
                    class="readonly"
                    readonly value="{{importData.Info.GameVersion}}" 
                    nbInput fullWidth 
                    >
            </div>
          </div>

          <div class="grp grp-file-name">
            <label class="label">Exported by</label>
            <div style="display: flex;">
              <input type="text" 
                    class="readonly"
                    readonly value="{{importData.Info.Owner}}" 
                    nbInput fullWidth 
                    >
            </div>
          </div>

          <div class="grp grp-file-name">
            <label class="label">Export time</label>
            <div style="display: flex;">
              <input type="text" 
                    class="readonly"
                    readonly value="{{getExportTime()}}" 
                    nbInput fullWidth 
                    >
            </div>
          </div>

        </div>


        <ng-container *ngFor="let File of importData.Files">

          <div 
            class="export-files-holder"
            *ngIf="!uploading || isFileToImport(File.Name)"
            >
            <div class="export-file-name">
              <nb-checkbox
                *ngIf="!uploading"
                class="checkbox-name"
                [checked]="isFileToImport(File.Name)"
                (checkedChange)="onFileChecked(File.Name, $event)"
                ></nb-checkbox>
              <nb-checkbox
                *ngIf="uploading"
                [checked]="isFileLoaded(File.Name)"
                class="checkbox-name"
                disabled
                ></nb-checkbox>
                {{File.Name}} : <span>[{{importData.Info.GameVersion}}#{{File.Version}}] {{File.Db.Note}}</span>
            </div>
          </div>
        </ng-container>

      </nb-card-body>

    </nb-card>

  </nb-card-body>

</nb-card>
