import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-dialog-enter-string',
  templateUrl: 'dialog-enter-string.component.html',
  styleUrls: ['dialog-enter-string.component.scss'],
})
export class DialogEnterStringComponent {
  constructor(protected ref: NbDialogRef<DialogEnterStringComponent>) {}

  title:string        = "Enter String";
  placeholder:string  = "String";

  cancel() {
    this.ref.close();
  }

  submit(data) {
    this.ref.close(data);
  }
}
