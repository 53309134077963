export class ProjectInfoSettings {
    public backend:ProjectInfoBackend = new ProjectInfoBackend();
    public meta:ProjectInfoMeta = new ProjectInfoMeta();
}

export class ProjectInfoMeta {
    public icon:string;
    public isProd:boolean;
}
export class ProjectInfoBackend {
    public bucket:string;
}